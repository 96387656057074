export default class LeadService {

    getLeads() {
        return fetch('demo/data/leads.json').then(res => res.json()).then(d => d.data);
    }

    getOrcamentos() {
        return fetch('demo/data/orcamentos.json').then(res => res.json()).then(d => d.data);
    }

    getPedidos() {
        return fetch('demo/data/pedidos.json').then(res => res.json()).then(d => d.data);
    }

    getObras() {
        return fetch('demo/data/obras.json').then(res => res.json()).then(d => d.data);
    }

    getProjetos() {
        return fetch('demo/data/projetos.json').then(res => res.json()).then(d => d.data);
    }


    getProductsSmall() {
        return fetch('demo/data/products-small.json').then(res => res.json()).then(d => d.data);
    }

    getProductsMixed() {
        return fetch('demo/data/products-mixed.json').then(res => res.json()).then(d => d.data);
    }

    getProductsWithOrdersSmall() {
        return fetch('demo/data/products-orders-small.json').then(res => res.json()).then(d => d.data);
    }
}
